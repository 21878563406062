<template>
  <v-main class="app">
    <div id="turn">
      <p>
        <strong>PLEASE ROTATE YOUR DEVICE</strong>
        <BR />
        THIS PAGE IS BEST VIEWED IN PORTRAIT ORIENTATION
      </p>
    </div>
    <v-header class="header-desktop" fixed />
    <div
      class="app-wrapper-container"
      :style="{
        height: heightWrapper + 'px',
        'min-height': heightWrapperMin + 'px',
      }"
    >
      <div
        class="app-wrapper"
        :style="{
          height: heightWrapper + 'px',
          'min-height': heightWrapperMin + 'px',
        }"
      >
        <header role="banner" data-container="header" class="banner">
          <div
            data-component="nav"
            id="mainnav"
            class="c-nav"
            nav-opened="false"
          >
            <div data-component="navigation" class="l-header l-header--light">
              <div class="l-header__nav">
                <div class="l-header__logo" data-ref="headerLogo">
                  <img
                    fetch-priority="high"
                    src="https://www.ysl.com/on/demandware.static/-/Library-Sites-Library-SLP/default/dw91c66661/images/logo-small.svg"
                    alt="ALLER SUR LA PAGE D’ACCUEIL SAINT LAURENT"
                  />
                </div>
              </div>
              <div class="l-header__aside" data-ref="headeraside">
                <a href="https://www.ysl.com/fr-fr/rive-droite">
                  <span
                    class="header__burger_desk header__burger_desk--close"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </header>
        <section class="container">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lorem
            diam, malesuada vel finibus a, pulvinar in sapien. Contact us:
            <a href="mailto: bookings.rive-droite@ysl.com">
              bookings.rive-droite@ysl.com
            </a>
          </p>
        </section>
        <iframe
          fetchPriority="low"
          src="https://ib.guestonline.fr/instabook/bookings/b5IVv3T?locale=en"
          width="340px"
          height="450px"
          frameborder="0"
          allowTransparency="true"
          scrolling="yes"
          loading="lazy"
          class="booking"
          :style="{
            height: heightWrapper - 70 + 'px',
          }"
        ></iframe>
        <div class="hide-footer"></div>
      </div>
    </div>
    <div class="desktop-bg" @click="onRedirect()">
      <img
        src="https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png"
        srcset="
          https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png        1600w,
          https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/w_1200/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png  800w
        "
        sizes="100vw"
        loading="lazy"
        alt="placeholder"
      />
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      heightWrapper: 0,
      heightWrapperMin: 0,
    }
  },
  mounted() {
    window.setInterval(() => {
      this.heightWrapper = window.innerHeight - 60
      window.scrollTo(0, 0)
    }, 500)
    this.$nextTick(() => {
      this.heightWrapperMin = window.innerHeight - 60
    })
  },
  methods: {
    onRedirect() {
      console.log('onRedirect')
      if (window.innerWidth < 768) {
        return false
      }
      window.location.href = 'https://www.ysl.com/fr-fr/rive-droite'
    },
  },
}
</script>

<style type="scss">
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
body {
  overflow: hidden;
}

.app-wrapper-container {
  width: 100%;
  position: absolute;
  max-width: 340px;
  margin: auto;
  min-height: 540px;
  z-index: 10;
  background: white;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  overflow: hidden;
}

.app-wrapper {
  width: 100%;
  position: relative;
  max-width: 340px;
  margin: auto;
  min-height: 540px;
}

.hide-footer {
  height: 60px;
  position: absolute;
  bottom: 0;
  left: 50%;
  left: 50%;
  z-index: 5;
  background: white;
  width: 310px;
  transform: translateX(-50%);
}

.app-wrapper a {
  text-decoration: underline;
}

.banner {
  background: white;
}

.l-header {
  height: 56px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.l-header__nav {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1012;
  max-width: 50%;
  scrollbar-width: thin;
  scrollbar-color: #c3c3c3 transparent;
}

.l-header__logo {
  display: block;
  width: 192px;
  height: 55px;
}

.l-header__logo img {
  display: block;
  padding: 20px;
  width: 79%;
  aspect-ratio: 300 / 29;
}

body {
  font-family: Helvetica_Regular, Arial, sans-serif;
}

iframe {
  width: 340px;
  overflow: hidden;
}

.container {
  padding: 12px 20px;
  background: white;
  position: relative;
  z-index: 5;
  width: 285px;
}

.container p {
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.booking {
  position: relative;
  z-index: 1;
  margin-top: -75px;
}

.desktop-bg {
  width: 100%;
  height: 103vh;
  position: absolute;
  top: 0%;
  left: 0;
  z-index: 3;
  background-color: #000;
  display: block;
  cursor: pointer;
}

.desktop-bg img {
  width: 100%;
  height: 102vh;
  object-fit: cover;
  aspect-ratio: 2048 / 1280;
  object-position: top;
  filter: blur(2px) brightness(0.2);
}
.header-desktop {
  filter: blur(2px) brightness(0.2);
}

.desktop-bg .overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  opacity: 0.1;
  cursor: pointer;
}

/* .desktop-bg {
  display: none;
} */

.header__burger_desk {
  padding: 0 7px;
  margin: 0 13px 0 auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.847);
  font-family: icons;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

a {
  text-decoration: none;
}

.header__burger_desk--close:before {
  content: '\F113';
}

.header__navigation,
.header-desktop {
  display: none;
}

.app-wrapper {
  width: 100%;
  max-width: 340px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  height: 90vh;
  overflow: hidden;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .desktop-bg {
    display: block;
  }
  .header-desktop,
  .header__navigation {
    display: block;
  }
  .app-wrapper {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .app-wrapper-container {
    max-width: 482px;
  }
}

#turn {
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 100;
  display: none;
  text-align: center;
  line-height: 14px;
}

#turn strong {
  font-weight: bold;
}

#turn p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
}

@media only screen and (min-device-width: 350px) and (max-device-width: 1000px) and (min-aspect-ratio: 13/9) {
  #turn {
    display: block;
  }
}
</style>
