var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('div',{attrs:{"id":"turn"}},[_c('p',[_c('strong',[_vm._v("PLEASE ROTATE YOUR DEVICE")]),_c('BR'),_vm._v(" THIS PAGE IS BEST VIEWED IN PORTRAIT ORIENTATION ")],1)]),_c('v-header',{staticClass:"header-desktop",attrs:{"fixed":""}}),_c('div',{staticClass:"app-wrapper-container",style:({
      height: _vm.heightWrapper + 'px',
      'min-height': _vm.heightWrapperMin + 'px',
    })},[_c('div',{staticClass:"app-wrapper",style:({
        height: _vm.heightWrapper + 'px',
        'min-height': _vm.heightWrapperMin + 'px',
      })},[_c('header',{staticClass:"banner",attrs:{"role":"banner","data-container":"header"}},[_c('div',{staticClass:"c-nav",attrs:{"data-component":"nav","id":"mainnav","nav-opened":"false"}},[_c('div',{staticClass:"l-header l-header--light",attrs:{"data-component":"navigation"}},[_c('div',{staticClass:"l-header__nav"},[_c('div',{staticClass:"l-header__logo",attrs:{"data-ref":"headerLogo"}},[_c('img',{attrs:{"fetch-priority":"high","src":"https://www.ysl.com/on/demandware.static/-/Library-Sites-Library-SLP/default/dw91c66661/images/logo-small.svg","alt":"ALLER SUR LA PAGE D’ACCUEIL SAINT LAURENT"}})])]),_c('div',{staticClass:"l-header__aside",attrs:{"data-ref":"headeraside"}},[_c('a',{attrs:{"href":"https://www.ysl.com/fr-fr/rive-droite"}},[_c('span',{staticClass:"header__burger_desk header__burger_desk--close"})])])])])]),_c('section',{staticClass:"container"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lorem diam, malesuada vel finibus a, pulvinar in sapien. Contact us: "),_c('a',{attrs:{"href":"mailto: bookings.rive-droite@ysl.com"}},[_vm._v(" bookings.rive-droite@ysl.com ")])])]),_c('iframe',{staticClass:"booking",style:({
          height: _vm.heightWrapper - 70 + 'px',
        }),attrs:{"fetchPriority":"low","src":"https://ib.guestonline.fr/instabook/bookings/b5IVv3T?locale=en","width":"340px","height":"450px","frameborder":"0","allowTransparency":"true","scrolling":"yes","loading":"lazy"}}),_c('div',{staticClass:"hide-footer"})])]),_c('div',{staticClass:"desktop-bg",on:{"click":function($event){return _vm.onRedirect()}}},[_c('img',{attrs:{"src":"https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png","srcset":"https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png" + " 1600w, " + "https://res.cloudinary.com/neuvieme-page/image/upload/f_auto/w_1200/v1661783688/sushi-park/Capture_d_e%CC%81cran_2022-08-29_a%CC%80_16.33.33.png" + " 800w","sizes":"100vw","loading":"lazy","alt":"placeholder"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }